<template lang="pug">
v-card(data-test="exam-modal")
  v-card-title
    v-btn(icon color="black" @click="$emit('back')")
      v-icon mdi-arrow-left
    span {{ title }}
    v-spacer
    v-switch(v-if="isCreateExam" v-model="formData.rangeMode" label="Range mode").range-switch

  v-divider
  v-card-text
    examBody(
      :formData="formData"
      :formErrors="formErrors"
      :showing="showing"
      :currentDate="currentDate"
      :isCreateExam="isCreateExam"
      :getExamLocation="getExamLocation"
    )
      slot(name="body")
  v-divider
  v-card-actions
    slot(name="actions" :formData="formData")
      v-spacer
      v-btn(outlined width="80") Cancel
      v-btn(color="primary" width="80" data-test="exam-create") Add
</template>

<script>
import { mapActions } from "vuex"
import { convertToDefaultBackendFormat, DDMMYYYYFormatting } from '@/util'
import featureFlagsMixin from '@/mixins/featureFlags.mixin'

export default {
  name: 'ExamModalBody',
  mixins: [featureFlagsMixin],
  props: {
    courseDate: String,
    formErrors: Object,
    editData: Object,
    showing: Boolean,
    title: String,
    isCreateExam: Boolean
  },
  data: () => ({
    formData: {
      licenseType: null,
      location: '',
      timeFrom: '',
      timeTo: '',
      date: '',
      dateFrom: null,
      dateTo: null,
      exam_date_future_days: null,
      rangeMode: null,
    },
    currentDate: convertToDefaultBackendFormat(new Date(Date.now()))
  }),

  mounted() {
    this.loadDefaultFormData()
  },

  methods: {
    ...mapActions({
      getExamLocation: 'crmExamLocation/list',
    }),

    loadDefaultFormData() {
      Object.assign(this.formData, this.editData, {
        date: this.courseDate ? this.courseDate : DDMMYYYYFormatting(this.editData.date)
      })

      if (!this.editData.exam_date_future_days) {
        Object.assign(this.formData, this.editData, {
          dateFrom: this.courseDate ? this.courseDate : DDMMYYYYFormatting(this.editData.dateFrom),
          dateTo: this.courseDate ? this.courseDate : DDMMYYYYFormatting(this.editData.dateTo)
        })
      }
      if (!this.isCreateExam) return
      this.formData.rangeMode = !this.formData.exam_date_future_days;
    },
  },


  components:{
    examBody: () => import('./CmExamBody.vue')
  }
}
</script>

<style lang="scss">
.range-switch {
  margin: 0;
  padding: 0;
  .v-input__control {
  .v-input__slot {
    margin: 0;
  }
    .v-messages {
      display: none;
    }
  }
}
</style>
